<template>
  <v-card class="pa-4">
    <v-row class="d-flex admin-report-card">
      <v-col
        md="3"
        sm="12"
        cols="12"
        class="d-flex align-center">
        <p class="text-h6">
          LINE Linked Report (Branch)
        </p>
      </v-col>
      <v-col
        md="6"
        sm="12"
        cols="12"
        class="d-flex align-center">
        <date-picker v-model="dateSelected" />
      </v-col>
      <v-col
        md="3"
        sm="12"
        cols="12">
        <v-spacer />
        <v-btn
          color="success"
          class="mt-2"
          block
          @click="exportCsv()">
          EXPORT CSV
        </v-btn>
      </v-col>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="items"
          hide-default-footer
          :items-per-page="-1"
          :loading="fetching"
          dense>
          <template #[`item.billCount`]="{ item }">
            {{ item.billCount | showNumberFormat() }}
          </template>
          <template #[`item.telCount`]="{ item }">
            {{ item.telCount | showNumberFormat() }}
          </template>
          <template #[`item.lineCount`]="{ item }">
            {{ item.lineCount | showNumberFormat() }}
          </template>
          <template #[`item.linePercentage`]="{ item }">
            {{ linePercentageCalculate(item.telCount, item.lineCount) }} %
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex'
import AdminAndCashierReportProvider from '@/resources/AdminAndCashierReportProvider'
import { ExportToCsv } from 'export-to-csv'
import DatePicker from '@/components/DateRangePicker.vue'

const AdminAndCashierReportService = new AdminAndCashierReportProvider()

export default {
  components: {
    DatePicker
  },
  data () {
    return {
      fetching: false,
      headers: [
        { text: 'Branch', value: 'warehouse.name' },
        { text: 'Bill Count', value: 'billCount' },
        { text: 'Tel Count', value: 'telCount' },
        { text: 'LINE Linked', value: 'lineCount' },
        { text: 'LINE Percentage', value: 'linePercentage' }
      ],
      dateSelected: [this.$dayjs().subtract(6, 'day').format(), this.$dayjs().format()],
      items: []
    }
  },
  watch: {
    dateSelected: {
      handler () {
        this.fetchReport()
      },
      deep: true
    }
  },
  mounted () {
    this.fetchReport()
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Components/setSnackbar',
      setModal: 'Components/setModal',
      setLoading: 'Components/setLoading'
    }),
    linePercentageCalculate (total, lineCount) {
      if (total <= 0) {
        return '0.00'
      }

      return ((lineCount / total) * 100).toFixed(2)
    },
    async fetchReport () {
      try {
        this.fetching = true
        const { data } = await AdminAndCashierReportService.getTelAndLineCountWarehouseReport({
          startDate: this.$dayjs(this.dateSelected[0])
                      .hour(0)
                      .minute(0)
                      .second(0)
                      .format(),
          endDate: this.$dayjs(this.dateSelected[1]).add(1, 'day')
                      .hour(0)
                      .minute(0)
                      .second(0)
                      .format()
        })
        this.items = data.sort((a, b) => a.warehouse.name.localeCompare(b.warehouse.name))
      } catch (error) {
        console.error(error)
        this.setSnackbar({
          value: true,
          message: `[error on fetch report]: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.fetching = false
      }
    },
    exportCsv () {
      const preExport = this.items.map((item) => ({
        warehouse: item.warehouse.name,
        billCount: item.billCount,
        telCount: item.telCount,
        lineCount: item.lineCount,
        linePercentage: this.linePercentageCalculate(item.telCount, item.lineCount)
      }))

      const options = {
        filename: `LINE-COUNT-WAREHOUSE(${this.$dayjs().format('YYYY-MM-DDTHH-mm')})`,
        showLabels: true,
        useKeysAsHeaders: true
      }

      const csvExporter = new ExportToCsv(options)
      csvExporter.generateCsv(preExport)
    }
  }
}
</script>
